import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-main-slides">
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/1.jpg"
          src="./images/1.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">Mpana International Consultants Co. Ltd</h3>
          {/* <p className="slide-p slide-p1">Dream It, Build It</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/43.jpeg"
          src="./images/3.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p"> We provide high-quality Engineering Consultancy Services</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/53.jpeg"
            src="./images/2.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
           We provide professional Business Consultancy Services
          </p>
        </Carousel.Caption>

      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/8.jpg"
          alt="Third slide"
        />

          <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
         We also do Agricultural and Natural Resource Management
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/9.jpg"
          alt="Third slide"
        />

          <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
         We give you the best deals in General Supplies
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFade;